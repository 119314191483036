// import RequestServices from './components/RequestServices';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import MainContent from './components/MainContent';
import Header from './components/Header';
import About from './components/About';
import ContactUs from './components/ContactUs';

function App() {
  return (
      <div className="full-site">
        <Router>
        <Header />
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactUs />} />
          </Routes>
        </Router>
      </div>
  );
}

export default App;