import React from 'react';
import './Footer.css';

const Footer = () => (
    <footer className="footer-container">
      <div className="social-icons">
        <a href="https://www.facebook.com/profile.php?id=100009337775561&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="https://instagram.com/atf_personaltraining?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://www.tiktok.com/@atfpersonaltraining" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-tiktok"></i>
      </a>
      </div>
      <div className="copyright">
        &copy; {new Date().getFullYear()} Advanced Tranformation Fitness, LLC
      </div>
    </footer>
);

export default Footer;