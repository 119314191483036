import React from 'react';
import './MainContent.css';
import mainImage from '../images/atf-landing-image-0328.jpg';

const MainContent = () => {
    return (
      <div className="main-content">
        <img src={mainImage} alt="Advanced Transformation Fitness trainer and client in gym" className="main-image"/>
        <p className="main-text">I HELP WOMEN REACH THEIR DREAM BODY WITHOUT GIVING UP THEIR SOCIAL LIFE.</p>
        <div className="main-guides">
          <a href="https://mailchi.mp/8dafe906a385/andrewtaylor" target="_blank" rel="noopener noreferrer" className="link with-thumbnail" style={{ "--animation-delay": "1s" }}>
            <div className="thumbnail-wrapper">
              <img alt="FREE 12 WEEK Transformation Email Updates thumbnail" src="https://media.bio.site/sites/e2390430-b9e4-4378-a00e-84b5c4a9d669/XrdS35DJ5Jjn2yBoS5zGS7.jpg" width="56" height="56"/>
              <div className="link-text">
                FREE 12 WEEK Transformation Email Updates
              </div>
            </div>
          </a>
          <a href="https://payhip.com/b/XKVcE" target="_blank" rel="noopener" className="link with-thumbnail" style={{ "--animation-delay": "2s" }}>
            <div className="thumbnail-wrapper">
              <img alt="FREE EBOOK Training & Nutrition Fundamentals  thumbnail" src="https://media.bio.site/sites/e2390430-b9e4-4378-a00e-84b5c4a9d669/M9LMT8qkPaLzPgMx5BtbLS.png" width="56" height="56" />
              <div className="link-text">
                FREE EBOOK Training &amp; Nutrition Fundamentals
              </div>
            </div>
          </a>
        </div>
        
        
      </div>
      );
};

export default MainContent;