import React from 'react';
import ReactDom from 'react-dom/client';
import App from './App';
import Footer from './components/Footer';
import './styles.css';
import './fonts.css';

const el = document.getElementById('root');
const root = ReactDom.createRoot(el);

root.render(
  <React.StrictMode>
      <div className="app-wrapper">
        <div className="content">
          <App />
        </div>
        <Footer />
      </div>
  </React.StrictMode>
);