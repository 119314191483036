import React from 'react';
import './About.css';
import aboutImage from '../images/ay8i9385.jpg';

const About = () => {
  return (
    <div className="about-section">
      <div className="about-content-container">
        <div className="about-content">
          <img src={aboutImage} alt="About me" className="about-image" />
          <p className="about-text">
            Welcome to ATF! I'm Andrew. I have been immersed in the realm of fitness throughout my entire life, having grown up as the son of a dedicated individual who transformed our entire garage into a personal gym. From my early teenage years, I found myself captivated by the transformative effects of weightlifting on the human body and the underlying reasons behind these changes.
          </p>
          <p className="about-text">
            In my mid-20s, I embarked on my journey in the fitness industry, assuming the role of a trainer at LA Fitness for a remarkable three-year tenure. Throughout this time, I not only nurtured my own fitness endeavors but also constantly expanded my knowledge and expertise in the field.
          </p>
          <p className="about-text">
            I quickly discovered my true passion and purpose in life.
          </p>
          <p className="about-text">
            There is nothing more rewarding than transforming someone's personal aspirations into tangible reality. Witnessing individuals grow stronger each week, smashing their monthly goals, and experiencing the tremendous boost in self-confidence resulting from personal progress, as well as witnessing the physical transformations of their bodies, has provided me with unparalleled joy.
          </p>
          <p className="about-text">
            In my role as a trainer and coach, I am dedicated to instilling trust and respect in every interaction.
          </p>
          <p className="about-text">
            My lifelong passion, combined with my expertise and unwavering commitment to helping individuals achieve their goals, makes me an ideal partner in your fitness journey. Together, we will unlock your true potential and embark on a transformative path toward a stronger, healthier you.
          </p>
        </div>
      </div>
    </div >
  );
};

export default About;