import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigateTo = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  const goToExternalSite = () => {
    window.location.href = process.env.REACT_APP_EXTERNAL_SITE; 
  };

  return (
    <div className="header-container">
      <button className="hamburger-menu" onClick={toggleMenu}>
        ☰
      </button>
      <div className="desktop-menu">
        <button className="my-button" onClick={() => navigateTo('/')}>Home</button>
        <button className="my-button" onClick={() => navigateTo('/about')}>About</button>
        <button className="my-button" onClick={() => navigateTo('/contact')}>Contact</button>
      </div>
      <h1>ADVANCED TRANSFORMATION FITNESS</h1>
      <div className="desktop-menu">
        <button className="my-button-login" onClick={() => goToExternalSite()}>Login or Sign Up</button>
      </div>

      <div className={`side-menu ${isMenuOpen ? 'open' : ''}`} ref={menuRef}>
        <div className="top-buttons">
          <button className="my-button"onClick={() => navigateTo('/')}>Home</button>
          <button className="my-button"onClick={() => navigateTo('/about')}>About</button>
          <button className="my-button"onClick={() => navigateTo('/contact')}>Contact</button>
        </div>
        <div className="bottom-buttons">
          <button className="my-button-login" onClick={() => goToExternalSite()}>Login or Sign Up</button>
        </div>
      </div>
    </div>
  );
};

export default Header;